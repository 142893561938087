import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContentWrapper = styled.div`
  justify-content: center;
  display: flex;
  font-size: 1em;
  line-height: 32px;
  font-weight: 700;
`
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ marginTop: "15%" }}>
      <ContentWrapper>
        <p>NOT FOUND</p>
      </ContentWrapper>
      <ContentWrapper>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </ContentWrapper>
    </div>
  </Layout>
)

export default NotFoundPage
